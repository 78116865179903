@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
}

.wiggle {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: wiggle 0.2s infinite;
  animation-timing-function: ease-in-out;
  transform-origin: center;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes wiggle {
  0%,
  100% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(1deg);
  }
}
